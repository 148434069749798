.section-list {
	padding-top: 55px;
	padding-bottom: 50px;

	@include from($desktop) {
		padding-top: 90px;
	}

	.column.is-half-desktop {
		@include from($desktop) {
			padding-left: 100px;
		}
	}

	.list-element {
		border-bottom: 1px solid $middle;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	p {
		margin-bottom: 5px !important;

		&.small {
			color: $action;
			font-family: $inter-semibold;
		}
	}

	.button-container {
		margin-top: 50px;

		@include from($desktop) {
			margin-top: 70px;
		}
	}
}
