.header-menu {
	li {
		position: relative;

		@include from($desktop) {
			margin-left: 100px;
		}

		@include from($widescreen) {
			margin-left: 140px;
		}

		a {
			color: $body-light;
			font-family: $inter-medium;
			padding: 0;
			padding-bottom: 1px;
			transition: all 0.3s ease;

			&:hover {
				background: transparent;
				color: $secondary;

				&::after {
					@include from($desktop) {
						width: 100%;
						background: $secondary;
					}
				}
			}

			&::after {
				@include from($desktop) {
					content: "";
					display: inline;
					height: 1px;
					width: 0;
					background: $light;
					transition: all 0.3s ease;
				}
			}
		}

		&:first-child {
			margin: 0;
		}

		&.active {
			a {
				&::after {
					@include from($desktop) {
						width: 100%;
					}
				}

				&:hover {
					&::after {
						@include from($desktop) {
							background: $secondary;
						}
					}
				}
			}
		}
	}

	.button-container {
		width: 100%;
		justify-content: center;
		margin-bottom: 50px;

		@include from($desktop) {
			margin-left: 55px;
			width: auto;
			justify-content: flex-start;
			margin-bottom: 0;
		}

		@include from($widescreen) {
			margin-left: 75px;
		}
	}

	.menu-icon {
		cursor: pointer;
		right: 10px;
		top: 20px;
		user-select: none;
		padding: 20px 10px;
		background: transparent;
		border: 0;
		font-size: 0;

		&:focus {
			outline: none;
		}

		.navicon {
			background: $light;
			display: block;
			height: 2px;
			position: relative;
			transition: all 0.2s ease-out;
			width: 20px;

			&::before,
			&::after {
				background: $light;
				content: "";
				display: block;
				height: 100%;
				position: absolute;
				transition: all 0.2s ease-out;
				width: 100%;
			}

			&::before {
				top: 8px;
			}

			&::after {
				top: -8px;
			}
		}

		&.close {
			.navicon {
				background: transparent;

				&::before {
					transform: rotate(-45deg);
				}

				&::after {
					transform: rotate(45deg);
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				top: 0;
			}
		}
	}

	.menu {
		display: none;

		@include from($desktop) {
			display: block;
			opacity: 1 !important;
		}

		&.open {
			display: flex;
			flex-direction: column;
			position: absolute;
			// top: 70px;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			// min-height: calc(var(--vh, 1vh) * 100 - 70px);
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			background: $primary;
			overflow: auto;
			z-index: -1;

			.menu-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				flex-grow: 1;
			}

			.menu-list {
				align-items: flex-start !important;
				flex-direction: column;
				// padding: 60px 16px 16px;
				padding: 100px 16px 16px;

				li {
					width: 100%;
					height: 80px;
					border-bottom: 1px solid $middle;

					a {
						width: 100%;
						height: 100%;
						justify-content: center;
					}
				}
			}
		}
	}

	.nav-menu {
		flex-direction: column;
		height: 100%;
		flex-grow: 1;
		justify-content: space-between;

		@include from($desktop) {
			flex-direction: row;
			justify-content: flex-start;
		}
	}

	.footer-in-nav {
		width: 100%;
		color: $light;
		font-size: $fontsize-p-footer;
		line-height: $lineheight-p-footer;

		.footer-menu {
			width: 100%;

			li {
				display: inline;
				width: auto;

				a {
					color: $body-light;
					margin-right: 10px;
					position: relative;
					display: inline-flex;
					justify-content: center;

					&::after {
						content: "";
						display: inline-block;
						width: 1px;
						height: 16px;
						background: $light;
						margin-left: 10px;
					}
				}

				&:last-child {
					a {
						margin-right: 0;

						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
}
