// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

* {
	box-sizing: border-box;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
}

html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	font-family: $inter-regular;
	color: $body-dark;
	font-size: $fontsize-p;
	line-height: $lineheight-p;
	min-height: 100%;
	height: 100%;

	.body-content {
		padding-top: 70px;

		@include from($tablet) {
			padding-top: 80px;
		}

		h1 {
			font-size: $fontsize-h1-mobile;
			line-height: $lineheight-h1-mobile;
			font-family: $martel-semibold;

			@include from($tablet) {
				font-size: $fontsize-h1;
				line-height: $lineheight-h1;
			}
		}

		h1.small {
			font-size: $fontsize-h2-mobile;
			line-height: $lineheight-h2-mobile;
			font-family: $martel-semibold;

			@include from($tablet) {
				font-size: $fontsize-h2;
				line-height: $lineheight-h2;
			}
		}

		h2 {
			font-size: $fontsize-h3-mobile;
			line-height: $lineheight-h3-mobile;
			font-family: $martel-semibold;

			@include from($tablet) {
				font-size: $fontsize-h3;
				line-height: $lineheight-h3;
			}
		}

		h3 {
			font-size: $fontsize-h4-mobile;
			line-height: $lineheight-h4-mobile;
			font-family: $martel-semibold;

			@include from($tablet) {
				font-size: $fontsize-h4;
				line-height: $lineheight-h4;
			}
		}

		h4 {
			font-size: $fontsize-overline-mobile;
			line-height: $lineheight-overline-mobile;
			letter-spacing: $spacing-overline-mobile;
			font-family: $inter-bold;
			text-transform: uppercase;

			@include from($tablet) {
				font-size: $fontsize-overline;
				line-height: $lineheight-overline;
				letter-spacing: $spacing-overline;
			}
		}

		h5 {
			font-family: $inter-bold;
			font-size: $fontsize-p;
			line-height: $lineheight-p;
		}

		h6 {
			font-family: $inter-medium;
			font-size: $fontsize-p;
			line-height: $lineheight-p;
		}

		.overline {
			font-size: $fontsize-overline-mobile;
			line-height: $lineheight-overline-mobile;
			letter-spacing: $spacing-overline-mobile;
			font-family: $inter-bold;
			text-transform: uppercase;

			@include from($tablet) {
				font-size: $fontsize-overline;
				line-height: $lineheight-overline;
				letter-spacing: $spacing-overline;
			}
		}
	}

	.menu-font {
		font-size: $fontsize-menu-mobile;
		line-height: $lineheight-menu-mobile;
		letter-spacing: $spacing-menu-mobile;
		font-family: $inter-medium;
		text-transform: uppercase;

		@include from($desktop) {
			font-size: $fontsize-menu;
			line-height: $lineheight-menu;
			letter-spacing: $spacing-menu;
		}
	}

	a.small,
	p.small {
		font-size: $fontsize-p-small;
		line-height: $lineheight-p-small;
	}

	.caption {
		font-size: $fontsize-p-caption-mobile;
		line-height: $lineheight-p-caption-mobile;

		@include from($tablet) {
			font-size: $fontsize-p-caption;
			line-height: $lineheight-p-caption;
		}
	}

	a {
		color: $action;
		font-family: $inter-semibold;
		transition: color 0.3s ease;

		&:hover {
			color: $action-shade;
		}
	}

	.statement {
		font-size: $fontsize-statement-mobile;
		line-height: $lineheight-statement-mobile;
		font-family: $martel-semibold;

		@include from($tablet) {
			font-size: $fontsize-h4;
			line-height: $lineheight-h4;
		}
	}

	.footer-text {
		font-size: $fontsize-p-footer;
		line-height: $lineheight-p-footer;

		a {
			font-family: $inter-regular;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
