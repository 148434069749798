.section-hero-text-centered {
	padding-top: 40px;
	padding-bottom: 50px;
	position: relative;

	@media (min-width: $tablet) {
		padding-top: 80px;
		padding-bottom: 100px;
	}

	.container {
		width: 100%;
	}

	.image-container {
		position: absolute;
		width: 260px;

		@media (min-width: $tablet) {
			width: 360px;
		}

		img {
			width: 260px;
			height: auto;

			@media (min-width: $tablet) {
				width: 360px;
			}
		}
	}

	.text-container {
		padding-top: 40px;

		@media (min-width: $tablet) {
			padding-top: 125px;
		}
	}

	h1 {
		margin-bottom: 1.875rem;
		color: $primary-shade;
		width: 70%;
		margin: 0 auto;
		margin-bottom: 1.875rem;

		@media (min-width: $tablet) {
			margin-bottom: 1.563rem;
		}
	}

	.overline {
		color: $primary-shade;
	}

	.text {
		margin-top: 70px;

		@media (min-width: $tablet) {
			width: 60%;
			margin: 0 auto;
			margin-top: 70px;
		}
	}

	.button-container {
		margin-top: 40px;
	}
}
