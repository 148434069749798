.legal-page {
	padding-top: 40px;
	padding-bottom: 50px;
	min-height: 100vh;

	@include from($tablet) {
		padding-top: 80px;
		padding-bottom: 100px;
	}

	.title {
		font-size: $fontsize-h3-mobile;
		line-height: $lineheight-h3-mobile;
		font-family: $martel-semibold;

		@include from($tablet) {
			font-size: $fontsize-h3;
			line-height: $lineheight-h3;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $primary;
		margin-top: 1.5rem;
	}

	h1,
	h2 {
		margin-bottom: 0.5rem;
	}

	p {
		margin-bottom: 0.5rem;
	}

	ul {
		list-style-type: disc;
	}

	ul,
	ol {
		padding-left: 22px;

		li {
			margin-bottom: 0.5rem;
		}
	}

	strong {
		font-family: $inter-semibold;
	}
}
