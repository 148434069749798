.section-board {
	padding-top: 50px;

	@include from($tablet) {
		padding-top: 90px;
	}

	h3,
	h4 {
		color: $primary-shade;
	}

	h4 {
		margin-bottom: 0 !important;
	}

	.person-wrapper {
		background: $secondary-shade1;
		margin-top: 50px;
		padding-top: 50px;
		padding-bottom: 50px;

		@include from($tablet) {
			padding-top: 85px;
			padding-bottom: 75px;
		}
	}

	.person-columns {
		width: 100%;

		@include from($tablet) {
			width: 70%;
		}
	}

	.person-container {
		margin-bottom: 50px;

		@include from($tablet) {
			margin-bottom: 60px;
			min-height: 105px;
		}

		&:last-child {
			@include from($tablet) {
				margin-bottom: 0;
			}
		}
	}

	.person-container-right {
		&:last-child {
			margin-bottom: 0;

			@include from($tablet) {
				margin-bottom: auto;
			}
		}
	}
}
