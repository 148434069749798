.section-quote {
	padding-top: 40px;
	padding-bottom: 50px;

	@include from($tablet) {
		padding-top: 60px;
		padding-bottom: 100px;
	}

	.quote-container {
		position: relative;
		margin-bottom: 50px;

		@include from($tablet) {
			width: 60%;
		}

		.svg-container {
			position: absolute;
			z-index: -1;
			left: 0;
			right: 0;
			justify-content: center;

			@include from($tablet) {
				justify-content: flex-start;
			}
		}

		svg {
			width: 70px;

			@include from($tablet) {
				width: 135px;
			}
		}

		.statement {
			font-size: $fontsize-statement-mobile;
			line-height: $lineheight-statement-mobile;
			font-family: $martel-semibold;
			padding-top: 60px;

			@include from($tablet) {
				font-size: $fontsize-h4;
				line-height: $lineheight-h4;
				padding: 60px 16px 0;
			}
		}
	}

	.author-columns {
		flex-direction: column;

		@include from($tablet) {
			flex-direction: row;
		}
	}

	.author-container {
		margin-bottom: 15px;

		@include from($tablet) {
			margin-bottom: 0;
		}

		.image-container {
			margin-top: 16px;

			@include from($tablet) {
				margin-top: 30px;
			}

			img {
				width: 170px;
				height: auto;
			}
		}
	}

	.overline {
		margin-bottom: 3px !important;

		@include from($tablet) {
			margin-bottom: 0 !important;
		}
	}

	.support-container {
		margin-top: 25px;

		@include from($tablet) {
			margin-top: 110px;
		}

		.image-container {
			width: 185px;
			height: auto;
		}

		.small {
			@include from($tablet) {
				margin-bottom: 2rem;
			}
		}
	}
}
