footer {
	background: $primary;
	color: $body-light;
	padding: 40px 0 30px;

	@include from($tablet) {
		padding: 30px 0;
	}

	.footer-menu {
		li {
			a {
				color: $body-light;
				margin-right: 10px;
				position: relative;
				display: flex;
				justify-content: center;

				&::after {
					content: "";
					display: inline-block;
					width: 1px;
					height: 16px;
					background: $light;
					margin-left: 10px;
				}
			}

			&:last-child {
				a {
					margin-right: 0;

					&::after {
						display: none;
					}
				}
			}
		}
	}

	.image-container {
		width: 150px;

		@include from($tablet) {
			width: 190px;
		}

		picture {
			display: flex;
			align-items: center;

			@include from($tablet) {
				padding: 5px 0;
			}

			img {
				width: 150px;

				@include from($tablet) {
					width: 190px;
				}
			}
		}
	}

	.column-right {
		margin-bottom: 35px;

		@include from($tablet) {
			justify-content: flex-start !important;
			margin-bottom: 0;
		}
	}

	.column-left {
		margin-top: 8px;

		@include from($tablet) {
			justify-content: flex-end !important;
			margin-top: 0;
		}
	}
}
