// Inter
@font-face {
	font-family: "inter-regular";
	font-display: fallback;
	font-weight: normal;
	font-style: normal;
	src: url("/src/fonts/Inter-Regular.woff2") format("woff2"), /* Super Modern Browsers */
		url("/src/fonts/Inter-Regular.woff") format("woff"), /* Modern Browsers */
		url("/src/fonts/Inter-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
}

@font-face {
	font-family: "inter-medium";
	font-display: fallback;
	font-weight: normal;
	font-style: normal;
	src: url("/src/fonts/Inter-Medium.woff2") format("woff2"), /* Super Modern Browsers */
		url("/src/fonts/Inter-Medium.woff") format("woff"), /* Modern Browsers */
		url("/src/fonts/Inter-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
}

@font-face {
	font-family: "inter-semibold";
	font-display: fallback;
	font-weight: normal;
	font-style: normal;
	src: url("/src/fonts/Inter-SemiBold.woff2") format("woff2"), /* Super Modern Browsers */
		url("/src/fonts/Inter-SemiBold.woff") format("woff"), /* Modern Browsers */
		url("/src/fonts/Inter-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
}

@font-face {
	font-family: "inter-bold";
	font-display: fallback;
	font-weight: normal;
	font-style: normal;
	src: url("/src/fonts/Inter-Bold.woff2") format("woff2"), /* Super Modern Browsers */
		url("/src/fonts/Inter-Bold.woff") format("woff"), /* Modern Browsers */
		url("/src/fonts/Inter-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
}

// Martel
@font-face {
	font-family: "martel-semibold";
	font-display: swap;
	src: url("/src/fonts/Martel-DemiBold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
