.error-page {
	height: calc(100vh - 200px);

	h1,
	.overline {
		color: $primary-shade;
	}

	.container {
		padding-top: 100px;

		@include from($tablet) {
			padding-top: 200px;
		}
	}

	.image-container {
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;

		@include from($tablet) {
			padding-top: 80px;
		}

		svg {
			width: 240px;

			@include from($tablet) {
				width: 360px;
			}
		}
	}

	.button-container {
		margin-top: 30px;

		@include from($tablet) {
			margin-top: 50px;
		}
	}
}
