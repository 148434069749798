.section-slider {
	position: relative;

	.slider-container {
		height: 545px;

		@include from($tablet) {
			height: 465px;
		}

		.image-container {
			height: 100%;
			width: 100%;
		}

		picture {
			display: block;
			height: 100%;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}

	.text-container {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		pointer-events: none;
		padding-top: 50px;
		height: 545px;
		width: 100%;

		@include from($tablet) {
			align-items: center;
			padding-top: 0;
			height: 465px;
		}

		.columns {
			width: 100%;
		}

		h2 {
			margin-bottom: 30px;
		}
	}

	h2,
	p {
		color: $headings-light;
	}

	.button-container {
		margin-top: 50px;
		pointer-events: all;

		@include from($tablet) {
			margin-top: 40px;
		}
	}

	.swiper-pagination-bullet-active {
		background: $action;
	}

	.swiper-container-horizontal {
		> .swiper-pagination-bullets {
			bottom: 25px;

			.swiper-pagination-bullet {
				margin: 0 8px;
			}
		}
	}
}
