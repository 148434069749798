header {
	background: $primary;
	height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;

	@include from($tablet) {
		height: 80px;
	}

	.shadow-container {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		box-shadow: 0 1px 20px 5px rgb(15 34 84 / 5%), 0 2px 20px 5px rgb(15 34 84 / 6%);
		z-index: 3;
	}

	.container,
	.columns,
	.column {
		height: 100%;
	}

	.columns {
		width: 100%;
	}
}
