.section-cards {
	padding-top: 60px;

	@include from($tablet) {
		padding-top: 100px;
	}

	h2 {
		margin-bottom: 1rem;
	}

	.intro {
		margin-bottom: 200px;
	}

	.text {
		@include from($tablet) {
			width: 500px;
		}
	}

	.card-box {
		box-shadow: 0 1px 20px rgba(15, 34, 84, 0.03), 0 4px 10px rgba(15, 34, 84, 0.03);
		border-radius: 20px;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 35px;
		margin-bottom: 50px;
		background: $light;

		@include from($tablet) {
			margin-bottom: 80px !important;
			min-height: 185px;
		}
	}

	.overline {
		color: $primary-shade;
		margin-bottom: 1rem !important;
	}

	.ic {
		img {
			width: 80px;
			height: 80px;
			margin-top: -40px;
			margin-bottom: 20px;
		}
	}

	svg.ic {
		width: 80px;
		height: 80px;
		margin-top: -40px;
		margin-bottom: 20px;
	}

	.button-container {
		margin-top: 10px;
	}

	.card-container {
		background: $secondary-shade1;
		position: relative;
	}

	.card-container > div {
		position: relative;
		top: -100px;
	}

	.card-text {
		font-size: $fontsize-p-caption-mobile;
		line-height: $lineheight-p-caption-mobile;

		@media (min-width: $tablet) {
			font-size: $fontsize-p;
			line-height: $lineheight-p;
		}
	}
}
