.button-round {
	a {
		font-size: $fontsize-button;
		line-height: $lineheight-button;
		letter-spacing: $spacing-button;
		font-family: $inter-semibold;
		text-transform: uppercase;
		padding: 16px 32px;
		color: $light;
		background: $action;
		border-radius: 100px;
		transition: all 0.3s ease;

		&:hover {
			color: $light;
			background: $action-shade;
		}
	}
}

.button-flat {
	a {
		font-size: $fontsize-button-flat-mobile;
		line-height: $lineheight-button-flat-mobile;
		letter-spacing: $spacing-button-flat-mobile;
		font-family: $inter-bold;
		text-transform: uppercase;
		color: $action;
		transition: color 0.3s ease;

		@include from($tablet) {
			font-size: $fontsize-button-flat;
			line-height: $lineheight-button-flat;
			letter-spacing: $spacing-button-flat;
		}

		.ic-back {
			transform: rotate(180deg);
			margin-right: 8px;
		}

		&:hover {
			color: $action-shade;

			svg {
				path {
					fill: $action-shade;
				}
			}
		}
	}
}
