.section-hero-text {
	padding-top: 16px;
	padding-bottom: 40px;
	position: relative;
	overflow: hidden;

	@include from($tablet) {
		padding-top: 110px;
		padding-bottom: 60px;
	}

	h1 {
		margin-bottom: 25px;

		@include from($tablet) {
			margin-bottom: 30px;
		}
	}

	.button-container {
		margin-top: 40px;
	}

	.image-container {
		position: absolute;
		width: 200px;
		right: -110px;

		@include from($tablet) {
			width: 360px;
			right: -170px;
		}

		img {
			width: 200px;
			height: auto;

			@include from($tablet) {
				width: 360px;
			}
		}
	}

	.text-container {
		padding-top: 60px;

		@include from($tablet) {
			padding-top: 50px;
		}
	}
}
