.section-event-hero {
	background: $primary;
	color: $light;
	position: relative;
	overflow: hidden;
	padding-top: 45px;
	padding-bottom: 50px;

	@include from($tablet) {
		padding-top: 55px;
		padding-bottom: 60px;
	}

	.image-container {
		position: absolute;
		width: 300px;
		right: -150px;
		top: 20px;
	}

	.event-info {
		flex-direction: column;
		margin-top: 35px;

		@include from($tablet) {
			flex-direction: row;
			margin-top: 25px;
		}
	}

	.date,
	.location {
		svg {
			margin-right: 8px;
		}
	}

	.location {
		margin-top: 8px;

		@include from($tablet) {
			margin-left: 25px;
			margin-top: 0;
		}
	}

	.button-container {
		margin-bottom: 65px;

		@include from($tablet) {
			margin-bottom: 90px;
		}
	}
}
