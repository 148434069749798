.section-events {
	padding-top: 70px;
	padding-bottom: 50px;

	@include from($tablet) {
		padding-top: 90px;
	}

	.event-container {
		background: $primary;
		color: $body-light;
		box-shadow: 0 1px 20px 5px rgb(15 34 84 / 3%), 0 4px 10px 5px rgb(15 34 84 / 3%);
		border-radius: 20px;
		// padding: 25px;
		margin-bottom: 15px;
		flex-direction: column;
		width: 100%;

		@include from($tablet) {
			width: 80%;
			// padding: 25px 40px;
			margin-bottom: 25px;
			flex-direction: row;
		}
	}

	.event-info-container {
		padding: 25px;
		padding-bottom: 15px;

		@include from($tablet) {
			padding: 25px 40px;
		}
	}

	h2 {
		margin-bottom: 30px;
	}

	h3 {
		color: $headings-light;
		margin-bottom: 15px;
	}

	.event-info {
		margin-bottom: 30px;
		flex-direction: column;

		@include from($tablet) {
			flex-direction: row;
		}
	}

	.date,
	.location {
		svg {
			margin-right: 8px;
		}
	}

	.location {
		margin-top: 8px;

		@include from($tablet) {
			margin-left: 25px;
			margin-top: 0;
		}
	}

	.button-container {
		@include from($tablet) {
			justify-content: center;
		}

		a {
			padding: 25px;

			@include from($tablet) {
				padding: 25px 40px;
			}
		}
	}
}
