// Colors
$primary: #29407b;
$primary-shade: #0f2254;
$secondary: #d0dbf6;
$secondary-shade1: #eaeffb;
$secondary-shade2: #d0dbf6;
$action: #86a8fd;
$action-shade: #adc2f7;
$error: #e75a7c;
$bg-lightmode: #fbfbfb;
$headings-light: #fff;
$body-light: #fff;
$body-dark: #202835;
$middle: #b4b8b8;
$headings-dark: #0f2254;
$bg-darkmode: #29407b;
$icon-color: #29407b;
$light: #fff;
$dark: #000;

// Fonts
$inter-regular: "inter-regular", sans-serif; // 400
$inter-medium: "inter-medium", sans-serif; // 500
$inter-semibold: "inter-semibold", sans-serif; // 600
$inter-bold: "inter-bold", sans-serif; // 700
$martel-semibold: "martel-semibold", serif; // 600

// Font Sizes
$fontsize-h1: 3.75rem; // 60px
$fontsize-h2: 3rem; // 48px
$fontsize-h3: 2rem; // 32px
$fontsize-h4: 1.5rem; // 24px
$fontsize-overline: 0.875rem; // 14px
$fontsize-p: 1rem; // 16px
$fontsize-p-small: 0.875rem; // 14px
$fontsize-p-caption: 0.75rem; // 12px
$fontsize-button: 0.875rem; // 14px
$fontsize-button-flat: 1rem; // 16px
$fontsize-menu: 1rem; // 16px
$fontsize-p-footer: 0.75rem; // 12px

$fontsize-h1-mobile: 2.25rem; // 36px
$fontsize-h2-mobile: 1.375rem; // 22px
$fontsize-h3-mobile: 1.25rem; // 20px
$fontsize-h4-mobile: 1.125rem; // 18px
$fontsize-overline-mobile: 0.813rem; // 13px
$fontsize-button-flat-mobile: 1.063rem; // 17px
$fontsize-p-caption-mobile: 0.875rem; // 14px
$fontsize-statement-mobile: 1.125rem; // 18px
$fontsize-menu-mobile: 1.375rem; // 22px

// Line Heigths
$lineheight-h1: 4.5rem; // 72px
$lineheight-h2: 3.5rem; // 56px
$lineheight-h3: 2.5rem; // 40px
$lineheight-h4: 2.5rem; // 40px
$lineheight-overline: 1rem; // 16px
$lineheight-p: 1.5rem; // 24px
$lineheight-p-small: 1.375rem; // 22px
$lineheight-p-caption: 1rem; // 16px
$lineheight-button: 1.125rem; // 18px
$lineheight-button-flat: 1rem; // 16px
$lineheight-menu: 1.25rem; // 20px
$lineheight-p-footer: 1rem; // 16px

$lineheight-h1-mobile: 2.75rem; // 44px
$lineheight-h2-mobile: 1.75rem; // 28px
$lineheight-h3-mobile: 1.563rem; // 25px
$lineheight-h4-mobile: 1.625rem; // 26px
$lineheight-overline-mobile: 0.875rem; // 14px
$lineheight-button-flat-mobile: 1.25rem; // 20px
$lineheight-p-caption-mobile: 1.25rem; // 20px
$lineheight-statement-mobile: 1.75rem; // 28px
$lineheight-menu-mobile: 1.625rem; // 26px

// Letter Spacing
$spacing-overline: 0.188rem; // 3px
$spacing-button: 0.1rem;
$spacing-button-flat: 0.1rem;
$spacing-menu: 0.1em;

$spacing-overline-mobile: 0.156rem; // 2.5px
$spacing-button-flat-mobile: 0.004rem; // 0.06px
$spacing-menu-mobile: 0.1em;
