.section-text-info {
	padding-top: 40px;
	padding-bottom: 70px;

	@include from($tablet) {
		padding-top: 80px;
		padding-bottom: 130px;
	}

	.column-left {
		padding-right: 3%;
	}

	.column-right {
		@include from($tablet) {
			padding-left: 80px;
		}
	}

	.sticky-container {
		position: relative;
		top: 0;

		@include from($tablet) {
			position: sticky;
			top: 160px;
		}
	}

	.image-container {
		margin-top: 70px;

		@include from($tablet) {
			margin-top: 50px;
		}

		.image {
			margin-bottom: 16px;

			@include from($tablet) {
				margin-bottom: 25px;
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $primary-shade;
		margin-top: 40px;
		margin-bottom: 1rem;

		@include from($tablet) {
			margin-top: 80px;
			margin-bottom: 1.5rem;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	strong {
		font-weight: normal;
		font-family: $inter-bold;
	}

	p {
		margin-bottom: 0.5rem !important;
	}

	ul {
		list-style: disc;
		padding-left: 20px;
	}
}
